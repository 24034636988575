import { AuthErrors } from "~/types/auth-errors";

export default defineNuxtRouteMiddleware((to) => {
  const { data } = useAuth();

  // redirect to login if refresh token is expired
  if (
    to.path !== "/auth/logout" &&
    data.value?.error === AuthErrors.RefreshAccessTokenError
  ) {
    return navigateTo("/auth/logout");
  }
});
