<script lang="ts" setup>
import { type ITheme } from "./composables/useTheme";
import { useNotify } from "./stores/notifications";

const theme = useState<ITheme>("theme.current");
const notify = useNotify();
const routeQuery = useRoute().query;
const { t, currentLocale, searchAndSet } = useLang();

// remove layout if app query is true, used for mobile webview
const nolayout = ref(undefined);

if (routeQuery?.app === "true") {
  nolayout.value = false;
  if (routeQuery.lang) {
    searchAndSet(routeQuery.lang.toString());
  }
  if (routeQuery.theme) {
    theme.value = routeQuery.theme.toString() as ITheme;
  }
}

useHead(() => ({
  titleTemplate: "%s | " + t("app.name"),
  link: [
    {
      rel: "icon",
      type: "image/png",
      href: theme.value === "dark" ? "/favicon_dark.png" : "/favicon_light.png",
    },
  ],
}));

useServerSeoMeta({
  titleTemplate: "%s | " + t("app.name"),
  title: t("app.name"),
  description: t("app.description"),
  keywords: t("app.keywords"),
  ogImage:
    "/assets/images/" +
    (theme.value === "dark" ? "logo_dark.webp" : "logo_light.webp"),
  ogUrl: "https://tahadu.app",
  ogType: "website",
  ogLocale: currentLocale.value.iso.toString(),
  ogSiteName: t("app.name"),
  ogTitle: t("app.name"),
  ogDescription: t("app.description"),
  twitterCard: "summary_large_image",
  twitterSite: "@tahadu_app",
  twitterCreator: "تهادوا",
  twitterTitle: t("app.name"),
  twitterDescription: t("app.description"),
  twitterImage:
    "/assets/images/" +
    (theme.value === "dark" ? "logo_dark.webp" : "logo_light.webp"),
});
</script>
<template>
  <Html :class="`${theme === 'dark' ? 'dark' : ''}`" :lang="currentLocale.iso">
    <Body
      class="antialiased duration-300 transition-colors text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-900"
    >
      <NuxtLayout :name="nolayout">
        <NuxtLoadingIndicator
          :height="5"
          :duration="3000"
          :throttle="400"
          :color="theme === 'dark' ? '#78989B' : '#1E5359'"
        />
        <NuxtPage :dir="currentLocale.dir" />
        <Notification
          v-for="n in notify.getNotifications"
          :key="n.title"
          :notification-id="n.notificationId"
          :dir="currentLocale.dir"
          :title="t(n.title)"
          :description="n.description ? t(n.description) : ''"
          :success="n.success"
        />
      </NuxtLayout>
    </Body>
  </Html>
</template>
<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.25s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
