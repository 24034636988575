import validate from "/home/node/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup@_xryetakdri4zi2dltqhvzl2fxi/node_modules/nuxt/dist/pages/runtime/validate.js";
import refresh_45token_45global from "/home/node/middleware/refresh-token.global.ts";
import short_45link_45global from "/home/node/middleware/short-link.global.ts";
import manifest_45route_45rule from "/home/node/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup@_xryetakdri4zi2dltqhvzl2fxi/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  refresh_45token_45global,
  short_45link_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/node/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.7_next-auth@4.22.5_rollup@4.13.2_vue@3.4.21/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}