export interface IApp {
  name: string;
  author: {
    name: string;
    link: string;
  };
}

export default defineAppConfig({
  // declare app information
  app: {
    name: "app.name",
    author: {
      name: "app.author",
      link: "https://khaled.sh/",
    },
  },
});
