// import VueGtag from 'vue-gtag';
import { createGtm } from "@gtm-support/vue-gtm";
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig().public;
  if (process.env.NODE_ENV !== "production" || !config.gtm) return;
  nuxtApp.vueApp.use(
    createGtm({
      id: config.gtm ?? "",
    }),
  );
});
