import { defineStore } from "pinia";
import type { INotification, INotificationState } from "./entites";

export const useNotify = defineStore("notifications", {
  state: (): INotificationState => ({
    notifications: [],
  }),
  actions: {
    reset() {
      this.$reset();
    },
    show(title: string, description?: string, success?: boolean): void {
      const notificationId =
        Date.now() + Math.random().toString(36).substring(2, 9);
      this.notifications.push({
        notificationId: notificationId.toString(),
        title: title ?? "notifications.unexpected_error",
        description: description ?? "",
        success: success ?? false,
      });
    },
    remove(notificationId: string): void {
      this.notifications = this.notifications.filter(
        (x) => x.notificationId !== notificationId,
      );
    },
  },
  getters: {
    getNotifications(): INotification[] {
      return (
        this.notifications.filter((n) => n.notificationId !== "") ??
        ([] as INotification[])
      );
    },
  },
});
