import type { LocaleObject } from "@nuxtjs/i18n";

export const useLang = () => {
  const { locale, t, locales, setLocale } = useI18n();

  return {
    t,
    setLocale,
    locale,
    availableLocales: computed(() => locales.value as LocaleObject[]),
    currentLocale: computed(
      () =>
        locales.value.find(
          (l) => typeof l !== "string" && l.code.toString() === locale.value,
        ) as LocaleObject,
    ),
    nextLocale: computed(
      (): LocaleObject =>
        locales.value.find(
          (l) => typeof l !== "string" && l.code.toString() !== locale.value,
        ) as LocaleObject,
    ),
    setByCode: (code?: string) => {
      if (locales.value.find((l) => typeof l !== "string" && l.code === code)) {
        setLocale(code);
      }
    },
    searchAndSet: (search: string) => {
      const found = useLang().availableLocales.value.find((l) =>
        l.code.toLowerCase().includes(search.toLowerCase()),
      );
      if (found) {
        setLocale(found.code);
      }
    },
  };
};
