import { default as _91_46_46_46slug_93RFGIAO9iqJMeta } from "/home/node/pages/[...slug].vue?macro=true";
import { default as _400MFPIuNncnpMeta } from "/home/node/pages/400.vue?macro=true";
import { default as _401URY7vJqFQkMeta } from "/home/node/pages/401.vue?macro=true";
import { default as _403zxPE1jWLbCMeta } from "/home/node/pages/403.vue?macro=true";
import { default as _500S2Pwyx3MuMMeta } from "/home/node/pages/500.vue?macro=true";
import { default as forgot_45passwordWLF2YJVNONMeta } from "/home/node/pages/auth/forgot-password.vue?macro=true";
import { default as logintWPYA7yfQgMeta } from "/home/node/pages/auth/login.vue?macro=true";
import { default as logoutUXbZyux9e5Meta } from "/home/node/pages/auth/logout.vue?macro=true";
import { default as registerB5zltZL0xvMeta } from "/home/node/pages/auth/register.vue?macro=true";
import { default as reset_45passworduANV9P3WcVMeta } from "/home/node/pages/auth/reset-password.vue?macro=true";
import { default as _91_46_46_46slug_93dNpcr3A8WPMeta } from "/home/node/pages/claim/[...slug].vue?macro=true";
import { default as indexHhb4iEcnD1Meta } from "/home/node/pages/coupons/index.vue?macro=true";
import { default as faqsCR8i2WNuJ6Meta } from "/home/node/pages/faqs.vue?macro=true";
import { default as indexjBvbyAgHxEMeta } from "/home/node/pages/index.vue?macro=true";
import { default as indextmqg0hJbiqMeta } from "/home/node/pages/my-account/claims/index.vue?macro=true";
import { default as indexhdu8RxGnpNMeta } from "/home/node/pages/my-account/index.vue?macro=true";
import { default as _91id_93X1KFjrTzeIMeta } from "/home/node/pages/my-account/wishlists/[id].vue?macro=true";
import { default as favoritesV7tM2ZM9rHMeta } from "/home/node/pages/my-account/wishlists/favorites.vue?macro=true";
import { default as indexI0EKHT6JOpMeta } from "/home/node/pages/my-account/wishlists/index.vue?macro=true";
import { default as _91_46_46_46slug_93RByZRJHRnBMeta } from "/home/node/pages/posts/[...slug].vue?macro=true";
import { default as indexMuTsXnmP90Meta } from "/home/node/pages/posts/index.vue?macro=true";
import { default as privacy_45policyRkHPYIFni5Meta } from "/home/node/pages/privacy-policy.vue?macro=true";
import { default as _91_46_46_46slug_93QvIXd3K7yGMeta } from "/home/node/pages/w/[...slug].vue?macro=true";
import { default as indexFoGDWPH69nMeta } from "/home/node/pages/wishlists/[slug]/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93RFGIAO9iqJMeta?.name ?? "slug",
    path: _91_46_46_46slug_93RFGIAO9iqJMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93RFGIAO9iqJMeta || {},
    alias: _91_46_46_46slug_93RFGIAO9iqJMeta?.alias || [],
    redirect: _91_46_46_46slug_93RFGIAO9iqJMeta?.redirect,
    component: () => import("/home/node/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _400MFPIuNncnpMeta?.name ?? "400",
    path: _400MFPIuNncnpMeta?.path ?? "/400",
    meta: _400MFPIuNncnpMeta || {},
    alias: _400MFPIuNncnpMeta?.alias || [],
    redirect: _400MFPIuNncnpMeta?.redirect,
    component: () => import("/home/node/pages/400.vue").then(m => m.default || m)
  },
  {
    name: _401URY7vJqFQkMeta?.name ?? "401",
    path: _401URY7vJqFQkMeta?.path ?? "/401",
    meta: _401URY7vJqFQkMeta || {},
    alias: _401URY7vJqFQkMeta?.alias || [],
    redirect: _401URY7vJqFQkMeta?.redirect,
    component: () => import("/home/node/pages/401.vue").then(m => m.default || m)
  },
  {
    name: _403zxPE1jWLbCMeta?.name ?? "403",
    path: _403zxPE1jWLbCMeta?.path ?? "/403",
    meta: _403zxPE1jWLbCMeta || {},
    alias: _403zxPE1jWLbCMeta?.alias || [],
    redirect: _403zxPE1jWLbCMeta?.redirect,
    component: () => import("/home/node/pages/403.vue").then(m => m.default || m)
  },
  {
    name: _500S2Pwyx3MuMMeta?.name ?? "500",
    path: _500S2Pwyx3MuMMeta?.path ?? "/500",
    meta: _500S2Pwyx3MuMMeta || {},
    alias: _500S2Pwyx3MuMMeta?.alias || [],
    redirect: _500S2Pwyx3MuMMeta?.redirect,
    component: () => import("/home/node/pages/500.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordWLF2YJVNONMeta?.name ?? "auth-forgot-password",
    path: forgot_45passwordWLF2YJVNONMeta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordWLF2YJVNONMeta || {},
    alias: forgot_45passwordWLF2YJVNONMeta?.alias || [],
    redirect: forgot_45passwordWLF2YJVNONMeta?.redirect,
    component: () => import("/home/node/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: logintWPYA7yfQgMeta?.name ?? "auth-login",
    path: logintWPYA7yfQgMeta?.path ?? "/auth/login",
    meta: logintWPYA7yfQgMeta || {},
    alias: logintWPYA7yfQgMeta?.alias || [],
    redirect: logintWPYA7yfQgMeta?.redirect,
    component: () => import("/home/node/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: logoutUXbZyux9e5Meta?.name ?? "auth-logout",
    path: logoutUXbZyux9e5Meta?.path ?? "/auth/logout",
    meta: logoutUXbZyux9e5Meta || {},
    alias: logoutUXbZyux9e5Meta?.alias || [],
    redirect: logoutUXbZyux9e5Meta?.redirect,
    component: () => import("/home/node/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: registerB5zltZL0xvMeta?.name ?? "auth-register",
    path: registerB5zltZL0xvMeta?.path ?? "/auth/register",
    meta: registerB5zltZL0xvMeta || {},
    alias: registerB5zltZL0xvMeta?.alias || [],
    redirect: registerB5zltZL0xvMeta?.redirect,
    component: () => import("/home/node/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passworduANV9P3WcVMeta?.name ?? "auth-reset-password",
    path: reset_45passworduANV9P3WcVMeta?.path ?? "/auth/reset-password",
    meta: reset_45passworduANV9P3WcVMeta || {},
    alias: reset_45passworduANV9P3WcVMeta?.alias || [],
    redirect: reset_45passworduANV9P3WcVMeta?.redirect,
    component: () => import("/home/node/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93dNpcr3A8WPMeta?.name ?? "claim-slug",
    path: _91_46_46_46slug_93dNpcr3A8WPMeta?.path ?? "/claim/:slug(.*)*",
    meta: _91_46_46_46slug_93dNpcr3A8WPMeta || {},
    alias: _91_46_46_46slug_93dNpcr3A8WPMeta?.alias || [],
    redirect: _91_46_46_46slug_93dNpcr3A8WPMeta?.redirect,
    component: () => import("/home/node/pages/claim/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexHhb4iEcnD1Meta?.name ?? "coupons",
    path: indexHhb4iEcnD1Meta?.path ?? "/coupons",
    meta: indexHhb4iEcnD1Meta || {},
    alias: indexHhb4iEcnD1Meta?.alias || [],
    redirect: indexHhb4iEcnD1Meta?.redirect,
    component: () => import("/home/node/pages/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: faqsCR8i2WNuJ6Meta?.name ?? "faqs",
    path: faqsCR8i2WNuJ6Meta?.path ?? "/faqs",
    meta: faqsCR8i2WNuJ6Meta || {},
    alias: faqsCR8i2WNuJ6Meta?.alias || [],
    redirect: faqsCR8i2WNuJ6Meta?.redirect,
    component: () => import("/home/node/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: indexjBvbyAgHxEMeta?.name ?? "index",
    path: indexjBvbyAgHxEMeta?.path ?? "/",
    meta: indexjBvbyAgHxEMeta || {},
    alias: indexjBvbyAgHxEMeta?.alias || [],
    redirect: indexjBvbyAgHxEMeta?.redirect,
    component: () => import("/home/node/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indextmqg0hJbiqMeta?.name ?? "my-account-claims",
    path: indextmqg0hJbiqMeta?.path ?? "/my-account/claims",
    meta: indextmqg0hJbiqMeta || {},
    alias: indextmqg0hJbiqMeta?.alias || [],
    redirect: indextmqg0hJbiqMeta?.redirect,
    component: () => import("/home/node/pages/my-account/claims/index.vue").then(m => m.default || m)
  },
  {
    name: indexhdu8RxGnpNMeta?.name ?? "my-account",
    path: indexhdu8RxGnpNMeta?.path ?? "/my-account",
    meta: indexhdu8RxGnpNMeta || {},
    alias: indexhdu8RxGnpNMeta?.alias || [],
    redirect: indexhdu8RxGnpNMeta?.redirect,
    component: () => import("/home/node/pages/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93X1KFjrTzeIMeta?.name ?? "my-account-wishlists-id",
    path: _91id_93X1KFjrTzeIMeta?.path ?? "/my-account/wishlists/:id()",
    meta: _91id_93X1KFjrTzeIMeta || {},
    alias: _91id_93X1KFjrTzeIMeta?.alias || [],
    redirect: _91id_93X1KFjrTzeIMeta?.redirect,
    component: () => import("/home/node/pages/my-account/wishlists/[id].vue").then(m => m.default || m)
  },
  {
    name: favoritesV7tM2ZM9rHMeta?.name ?? "my-account-wishlists-favorites",
    path: favoritesV7tM2ZM9rHMeta?.path ?? "/my-account/wishlists/favorites",
    meta: favoritesV7tM2ZM9rHMeta || {},
    alias: favoritesV7tM2ZM9rHMeta?.alias || [],
    redirect: favoritesV7tM2ZM9rHMeta?.redirect,
    component: () => import("/home/node/pages/my-account/wishlists/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexI0EKHT6JOpMeta?.name ?? "my-account-wishlists",
    path: indexI0EKHT6JOpMeta?.path ?? "/my-account/wishlists",
    meta: indexI0EKHT6JOpMeta || {},
    alias: indexI0EKHT6JOpMeta?.alias || [],
    redirect: indexI0EKHT6JOpMeta?.redirect,
    component: () => import("/home/node/pages/my-account/wishlists/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RByZRJHRnBMeta?.name ?? "posts-slug",
    path: _91_46_46_46slug_93RByZRJHRnBMeta?.path ?? "/posts/:slug(.*)*",
    meta: _91_46_46_46slug_93RByZRJHRnBMeta || {},
    alias: _91_46_46_46slug_93RByZRJHRnBMeta?.alias || [],
    redirect: _91_46_46_46slug_93RByZRJHRnBMeta?.redirect,
    component: () => import("/home/node/pages/posts/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexMuTsXnmP90Meta?.name ?? "posts",
    path: indexMuTsXnmP90Meta?.path ?? "/posts",
    meta: indexMuTsXnmP90Meta || {},
    alias: indexMuTsXnmP90Meta?.alias || [],
    redirect: indexMuTsXnmP90Meta?.redirect,
    component: () => import("/home/node/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyRkHPYIFni5Meta?.name ?? "privacy-policy",
    path: privacy_45policyRkHPYIFni5Meta?.path ?? "/privacy-policy",
    meta: privacy_45policyRkHPYIFni5Meta || {},
    alias: privacy_45policyRkHPYIFni5Meta?.alias || [],
    redirect: privacy_45policyRkHPYIFni5Meta?.redirect,
    component: () => import("/home/node/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93QvIXd3K7yGMeta?.name ?? "w-slug",
    path: _91_46_46_46slug_93QvIXd3K7yGMeta?.path ?? "/w/:slug(.*)*",
    meta: _91_46_46_46slug_93QvIXd3K7yGMeta || {},
    alias: _91_46_46_46slug_93QvIXd3K7yGMeta?.alias || [],
    redirect: _91_46_46_46slug_93QvIXd3K7yGMeta?.redirect,
    component: () => import("/home/node/pages/w/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexFoGDWPH69nMeta?.name ?? "wishlists-slug",
    path: indexFoGDWPH69nMeta?.path ?? "/wishlists/:slug()",
    meta: indexFoGDWPH69nMeta || {},
    alias: indexFoGDWPH69nMeta?.alias || [],
    redirect: indexFoGDWPH69nMeta?.redirect,
    component: () => import("/home/node/pages/wishlists/[slug]/index.vue").then(m => m.default || m)
  }
]