import revive_payload_client_fdcD1xolq2 from "/home/node/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup@_xryetakdri4zi2dltqhvzl2fxi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2mWVgWJKIo from "/home/node/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup@_xryetakdri4zi2dltqhvzl2fxi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_V22mVZz0hi from "/home/node/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup@_xryetakdri4zi2dltqhvzl2fxi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Xuw7F4lDJp from "/home/node/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup@_xryetakdri4zi2dltqhvzl2fxi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_sKta4SRMde from "/home/node/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup@_xryetakdri4zi2dltqhvzl2fxi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_lMXFAryFMa from "/home/node/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.13.2_typescript@5.4.3_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/node/.nuxt/components.plugin.mjs";
import prefetch_client_N55ur1HDj4 from "/home/node/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup@_xryetakdri4zi2dltqhvzl2fxi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Q9GzymTwnc from "/home/node/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.7_next-auth@4.22.5_rollup@4.13.2_vue@3.4.21/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import i18n_JokArpVhpD from "/home/node/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_rollup@4.13.2_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_izq2C6SpCZ from "/home/node/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup@_xryetakdri4zi2dltqhvzl2fxi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_brf4bdp8CY from "/home/node/node_modules/.pnpm/@vueuse+nuxt@10.9.0_nuxt@3.11.1_rollup@4.13.2_vue@3.4.21/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import gtm_cXsUZobmSF from "/home/node/plugins/gtm.ts";
import navbar_qw38FjZisw from "/home/node/plugins/navbar.ts";
import recaptcha_85gNSCNFUU from "/home/node/plugins/recaptcha.ts";
export default [
  revive_payload_client_fdcD1xolq2,
  unhead_2mWVgWJKIo,
  router_V22mVZz0hi,
  payload_client_Xuw7F4lDJp,
  check_outdated_build_client_sKta4SRMde,
  plugin_vue3_lMXFAryFMa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_N55ur1HDj4,
  plugin_Q9GzymTwnc,
  i18n_JokArpVhpD,
  chunk_reload_client_izq2C6SpCZ,
  ssr_plugin_brf4bdp8CY,
  gtm_cXsUZobmSF,
  navbar_qw38FjZisw,
  recaptcha_85gNSCNFUU
]