import { defineNuxtPlugin } from "#app";
import { VueReCaptcha } from "vue-recaptcha-v3";

export default defineNuxtPlugin((nuxtApp) => {
  if (process.env.NODE_ENV === "development") return;
  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey: useRuntimeConfig().public.recaptchaSiteKey,
    loaderOptions: {
      autoHideBadge: true,
    },
  });
});
