<script setup>
import { useNotify } from "../stores/notifications";
const notify = useNotify();
const props = defineProps({
  notificationId: {
    type: String,
    default: "",
  },
  success: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "Unexpected Error.",
  },
  description: {
    type: String,
    default: "",
  },
  timeout: {
    type: Number,
    default: 4000, // ms
  },
});

setTimeout(() => {
  notify.remove(props.notificationId);
}, props.timeout);
</script>
<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="mt-20 pointer-events-none fixed inset-0 flex px-4 py-6 sm:items-start sm:p-6 z-50"
  >
    <div class="flex w-full flex-col items-center space-y-4">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="notificationId"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-blue-900 bg-white"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <Icon
                  v-if="!success"
                  name="heroicons:exclamation-triangle"
                  class="h-6 w-6 text-red-400"
                  aria-hidden="true"
                />
                <Icon
                  v-else
                  name="heroicons:check-circle"
                  class="h-6 w-6 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 rtl:mr-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-bold">{{ title }}</p>
                <p class="mt-1 text-sm">{{ description }}</p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  class="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  @click="notify.remove(notificationId)"
                >
                  <span class="sr-only">Close</span>
                  <Icon
                    name="heroicons:x-mark-solid"
                    class="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
